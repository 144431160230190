import Onyx, { Image as OnyxPreview } from './onyx';
import Pikachu, { Image as PikachuPreview } from './pikachu';
import Gengar, { Image as GengarPreview } from './gengar';
import Castform, { Image as CastformPreview } from './castform';
import Glalie, { Image as GlaliePreview } from './glalie';
import Celebi, { Image as CelebiPreview } from './celebi';
import Adinuel, { Image as AdinuelPreview } from './adinuel';
import Adinuelv2, { Image as Adinuelv2Preview } from './adinuelv2';

export default [
  {
    key: 'adinuelv2',
    name: 'Adinuelv2',
    component: Adinuelv2,
    preview: Adinuelv2Preview,
  },
  {
    key: 'adinuel',
    name: 'Adinuel',
    component: Adinuel,
    preview: AdinuelPreview,
  },
  {
    key: 'onyx',
    name: 'Onyx',
    component: Onyx,
    preview: OnyxPreview,
  },
  {
    key: 'pikachu',
    name: 'Pikachu',
    component: Pikachu,
    preview: PikachuPreview,
  },
  {
    key: 'gengar',
    name: 'Gengar',
    component: Gengar,
    preview: GengarPreview,
  },
  {
    key: 'castform',
    name: 'Castform',
    component: Castform,
    preview: CastformPreview,
  },
  {
    key: 'glalie',
    name: 'Glalie',
    component: Glalie,
    preview: GlaliePreview,
  },
  {
    key: 'celebi',
    name: 'Celebi',
    component: Celebi,
    preview: CelebiPreview,
  },
];
