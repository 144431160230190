import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import AppContext from '../../context/AppContext';

const Adinuelv2 = () => {
  const context = useContext(AppContext);
  const { state } = context;
  const { data, theme } = state;

  const Header = () => (
    <div
      className="rounded flex flex-col justify-center"
    >
      <div className="flex flex-col justify-center">
        <h1 className="text-5xl font-bold leading-tight">
          {data.profile.firstName} {data.profile.lastName}
        </h1>
        <div className="font-medium tracking-wide">{data.profile.subtitle}</div>
      </div>
    </div>
  );

  const ContactItem = ({ icon, value, link = '#' }) =>
    value && (
      <div className="flex items-center my-1">
        <span className="material-icons text-lg mr-2" style={{ color: theme.colors.accent }}>
          {icon}
        </span>
        <a href={link}>
          <span className="font-medium break-all">{value}</span>
        </a>
      </div>
    );

  const Profile = () =>
    data.profile && (
      <div className="col-span-4 text-sm">
        <ContactItem
          icon="phone"
          value={data.profile.phone}
          link={`tel:${data.profile.phone}`}
        />
        <ContactItem
          icon="link"
          value={data.profile.website}
          link={`${data.profile.website}`}
        />
        <ContactItem
          icon="email"
          value={data.profile.email}
          link={`mailto:${data.profile.email}`}
        />
        <ContactItem
          icon="location_on"
          value={data.profile.address.line3}
          link={`https://www.google.com/maps/place/${data.profile.address.line3}`} />
      </div>
    );

  const Objective = () =>
    data.objective &&
    data.objective.enable && (
      <div>
        <ReactMarkdown className="text-sm" source={data.objective.body} />
      </div>
    );

  const Heading = ({ title }) => (
    <div
      className="mb-2 border-b-2 pb-1 font-bold uppercase tracking-wide text-sm"
      style={{ color: theme.colors.accent, borderColor: theme.colors.accent }}
    >
      {title}
    </div>
  );

  const SkillItem = x => (
    <span
      key={x.id}
      className="leading-none rounded-lg text-sm px-2 py-1 font-medium my-1 mr-2 bg-gray-300 py-2 my-1"
      style={{
        color: theme.colors.primary,
      }}
    >
      {x.skill}
    </span>
  );

  const Skills = () =>
    data.skills &&
    data.skills.enable && (
      <div>
        <Heading title={data.skills.heading} />
        <div className="mt-2 flex flex-wrap mb-6">{data.skills.items.map(SkillItem)}</div>
      </div>
    );

  const HobbyItem = x => (
    <span
      key={x.id}
      className="leading-none rounded-lg text-sm px-2 py-1 font-medium my-1 mr-2 bg-gray-300 py-2 my-1"
      style={{
        color: theme.colors.primary,
      }}
    >
      {x.hobby}
    </span>
  );

  const Hobbies = () =>
    data.hobbies &&
    data.hobbies.enable && (
      <div>
        <Heading title={data.hobbies.heading} />
        <div className="mt-1 flex flex-wrap mb-6">{data.hobbies.items.map(HobbyItem)}</div>
      </div>
    );

  const ReferenceItem = x => (
    <div key={x.id} className="flex flex-col">
      <h6 className="text-sm font-medium">{x.name}</h6>
      <span className="text-xs">{x.position}</span>
      <span className="text-xs">{x.phone}</span>
      <span className="text-xs">{x.email}</span>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const References = () =>
    data.references &&
    data.references.enable && (
      <div>
        <Heading title={data.references.heading} />
        <div className="grid grid-cols-2 gap-2 mb-6">
          {data.references.items.filter(x => x.enable).map(ReferenceItem)}
        </div>
      </div>
    );

  const LanguageItem = x => (
    <span
      key={x.id}
      className="leading-none rounded-lg text-sm px-2 py-1 font-medium my-1 mr-2 bg-gray-300 py-2 my-1"
      style={{
        color: theme.colors.primary,
      }}
    >
      {x.key}
    </span>
  );

  const Languages = () =>
    data.languages &&
    data.languages.enable && (
      <div>
        <Heading title={data.languages.heading} />
        <div className="mt-1 flex flex-wrap mb-6">{data.languages.items.map(LanguageItem)}</div>
      </div>
    );

  const ExtraItem = x => (
    <div key={x.id} className="text-sm my-1">
      <h6 className="text-xs font-bold">{x.key}</h6>
      <h6 className="">{x.value}</h6>
    </div>
  );

  const Extras = () =>
    data.extras &&
    data.extras.enable && (
      <div>
        <Heading title={data.extras.heading} />
        <div className="grid grid-cols-2">
          {data.extras.items.filter(x => x.enable).map(ExtraItem)}
        </div>
      </div>
    );

  const WorkItem = x => (
    <div key={x.id} className="mb-3">
      <div className="flex justify-between items-center">
        <div>
          <h6 className="font-semibold">{x.title}</h6>
          <p className="text-xs">{x.role}</p>
        </div>
        <span className="text-xs font-medium">
          ({x.start}
          {x.start &&
            <span> - </span>
          }
          {x.end})
        </span>
      </div>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Work = () =>
    data.work &&
    data.work.enable && (
      <div>
        <Heading title={data.work.heading} />
        <div className="flex flex-col mb-4">
          {data.work.items.filter(x => x.enable).map(WorkItem)}
        </div>
      </div>
    );

  const EducationItem = x => (
    <div key={x.id} className="mb-2">
      <div className="flex justify-between items-center">
        <div>
          <h6 className="font-semibold">{x.name}</h6>
          <p className="text-xs">{x.major}</p>
        </div>
        <div className="flex flex-col text-right items-end">
          <span className="text-sm font-bold" style={{ color: theme.colors.accent }}>
            {x.grade}
          </span>
          <span className="text-xs font-medium">
            ({x.start}
            {x.start &&
              <span> - </span>
            }
            {x.end})
          </span>
        </div>
      </div>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Education = () =>
    data.education &&
    data.education.enable && (
      <div>
        <Heading title={data.education.heading} />
        <div className="flex flex-col mb-4">
          {data.education.items.filter(x => x.enable).map(EducationItem)}
        </div>
      </div>
    );

  const AwardItem = x => (
    <div key={x.id} className="mb-2">
      <h6 className="font-semibold">{x.title}</h6>
      <p className="text-xs">{x.subtitle}</p>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Awards = () =>
    data.awards &&
    data.awards.enable && (
      <div>
        <Heading title={data.awards.heading} />
        <div className="flex flex-col mb-2">
          {data.awards.items.filter(x => x.enable).map(AwardItem)}
        </div>
      </div>
    );

  const CertificationItem = x => (
    <div key={x.id} className="mb-3">
      <h6 className="font-semibold">{x.title}</h6>
      <p className="text-xs">{x.subtitle}</p>
      <ReactMarkdown className="mt-2 text-sm" source={x.description} />
    </div>
  );

  const Certifications = () =>
    data.certifications &&
    data.certifications.enable && (
      <div>
        <Heading title={data.certifications.heading} />
        <div className="flex flex-col mb-2">
          {data.certifications.items.filter(x => x.enable).map(CertificationItem)}
        </div>
      </div>
    );

  return (
    <div
      className="p-10"
      style={{
        fontFamily: theme.font.family,
        backgroundColor: theme.colors.background,
        color: theme.colors.primary,
      }}
    >
      <div className="grid grid-cols-12 col-gap-6 row-gap-8">
        <div className="col-span-12">
          <div className="grid grid-cols-12 col-gap-6 row-gap-8">
            <div className={`${data.profile ? 'col-span-8' : 'col-span-12'}`}>
              <Header />
            </div>
            <Profile />
          </div>
          <hr className="my-2" />
          <Objective />
        </div>
        <div className="col-span-4 overflow-hidden">
          <Skills />
          <Hobbies />
          <Languages />
          <Certifications />
        </div>

        <div className="col-span-8">
          <Work />
          <Awards />
          <Education />
          <References />
          <Extras />
        </div>
      </div>
    </div>
  );
};

export default Adinuelv2;
